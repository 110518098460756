import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "src/layout"
import Column from "src/layout/column"
import Menu from "src/components/Menu"
import { parseQueryString } from "src/helpers/text"
import { formatInterval } from "src/helpers/date"

const Italic = styled.div`
  font-style: italic;
  span {
    font-style: normal;
  }
  strong {
    font-weight: 500;
  }
`

interface DataType {
  sales: { nodes: Sale[] }
}

const ThanksPage: GatsbyPage<DataType> = ({ location, data }) => {
  const { sale: saleId } = parseQueryString(location.search)
  const sale = saleId && data.sales.nodes.find(({ id }) => id === saleId)

  return (
    <Layout noindex>
      <Menu />
      <Column $center>
        <h1>Merci pour votre commande</h1>
        <Italic>
          <p>
            Vous allez recevoir un courriel de confirmation.
            <br />
            N’hésitez pas à nous contacter pour toute question.
          </p>
          {sale ? (
            <p>
              Au plaisir de vous retrouver à{" "}
              <span>
                <strong>{sale.place}</strong>,
                <br />
                {formatInterval(sale.date, sale.end)}
                <br />(
                <a href={sale.link} target="_blank" rel="noopener">
                  {sale.address}
                </a>
                ).
              </span>
            </p>
          ) : (
            <p>Au plaisir de vous retrouver lors de la distribution que vous avez choisie.</p>
          )}
          <p>Cédric et Carine</p>
        </Italic>
      </Column>
    </Layout>
  )
}

export default ThanksPage

export const query = graphql`
  query {
    sales: allContentfulSale {
      nodes {
        id: contentful_id
        date
        end
        place
        address
        link
      }
    }
  }
`
